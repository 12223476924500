import styled from "styled-components";

export const CardContainer = styled.div`
    position: relative;
`;
export const CardComponentsConatiner = styled.div`
    position: relative;
    & > * {
        left: 0;
    }
`;
export const CardEnemyStats = styled.div`
    position: relative;
    color: white;
    width: 100%;
    text-align: center;
    left: 0;
`;
export const CardStats = styled.div`
    position: absolute;
    color: white;
    width: 100%;
    left: 8%;
    display: flex;
    gap: 1%;
    & > * {
        margin-left: 32%;
    }
`;
export const CardRarityContainer = styled.div`
    position: relative;
    color: white;
    width: 70%;
    text-align: center;
    left: 15%;
    display: flex;
    bordeR: solid thin transparent;
`;
export const EmblemImage = styled.img`
    width: 20% !important;
`;
export const EmblemTextContainer = styled.div`
    width: 60% !important;
    display: flex;
    justify-content: center;
    bordeR: solid thin transparent;
`
export const EmblemText = styled.span`
    margin-top: auto;
    margin-bottom: auto;
`