import styled from "styled-components"
import { FightButton } from "components/UI/atom/button/FightButton"
import Card from "../Card"
import BATTLE_ABI from "ABI/Battle.json"
import { BATTLE_ADDRESS } from "helpers/constants"
import Web3 from 'web3';
import { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { getWinChance } from "../Card/helper"
import { useMoralis } from "react-moralis"
import { useDispatch } from "react-redux"
import { setCurrentEnemies } from "redux/reducer/userDetailsSlice"
export const MainSlider = (props) => {
    const monsters = props.monsters
    //const [enemies, setEnemies] = useState([0, 0, 0, 0])
    const currentChar = useSelector(state => state.userDetails.currentCharacter)
    const roster = useSelector(state => state.userDetails.roster)
    const address = useSelector(state => state.userDetails.address)
    const provider = useSelector(state => state.userDetails.provider)
    const [accuracy, setAccuracy] = useState(0)
    const enemies = useSelector(state => state.userDetails.currentEnemies)
    const dispatch = useDispatch();
    let i = 0;

    const web3 = new Web3(provider == null ? window.ethereum == null ? Web3.givenProvider : window.ethereum : provider);
    let battle_contract = new web3.eth.Contract(BATTLE_ABI, BATTLE_ADDRESS);

    const getEnemies = async () => {
        let enemyArray = []
        for(var i = 0; i < 4; i++) {
            var e = await battle_contract.methods.enemies(i).call();
            if(e) {
                enemyArray.push(e);
            }
        }
        dispatch(setCurrentEnemies(enemyArray));
    }

    useEffect(() => {
        if(enemies[0] == 0) {
            getEnemies();
        }
        if(address != '') {
            if(roster.length != 0) {
                var currChar = roster[currentChar];
                setAccuracy(currChar.charDetails.accuracy);
            }
        }
    })


    return (
        <Body className="container">
            <div className="row">
                {monsters.map(monster => (
                    <div className="col-md-3 monster-container" key={i}>
                        <Card level={monster.rate} isEnemyCard={true} isAnimated={false} width={200} defenseProps={{
                            defense: enemies[i],
                            chance: getWinChance(accuracy, enemies[i++])
                        }}/>
                        <FightButton enemy={monster.rate}>Fight!!!</FightButton>
                    </div>
                ))}
            </div>
        </Body>
    )
}

const Body = styled.div`
    margin-left: auto;
    margin-right: auto;
    padding: 0px 120px;
    text-align: center;
    .monsterCard {
        width: 215px;
        height: 340px;
    }
    .monster-container {
        display: flex; 
        flex-direction: column;
        align-items: center;
    }
    @media (max-width: 1399px) {
        padding: 0px 30px;
    }
    @media (max-width: 1199px) {
        padding: 0px;
    }
`