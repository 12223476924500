import { NavRewardsGroup } from "../../molecules/LabelGroup/NavRewardGroup"
import { NavClaimGroup } from "../../molecules/LabelGroup/NavClaimGroup"
import styled from "styled-components";
import { getInventory } from "../../../../helpers/web3helper";
export const Header = () => {
    // const [rewardState, setRewardState] = useState(0);
    //const temp = getInventory("0x83C9a4E67884dD0C31B88491d2B5eE91d9d97772");
    //console.log(temp);
    return (
        <Body>
            <NavRewardsGroup />
            <NavClaimGroup />
        </Body>
    );
}

const Body = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    text-align: center;
    padding: 0px 10px 0px 50px;
    margin-top: 7rem;
    @media (max-width: 1199px) {
        padding: 0px 10px 0px 10px;
    }

    @media (max-width: 991px) {
        padding: 0px;
        display: block;
    }
`