import { Router } from './router/Router'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import { MoralisProvider } from 'react-moralis'

function App() {
  return (
    <MoralisProvider appId="jVCF0gC02nfstZQ256nNd4qkZKjW7CRnAgLWXkFc" 
    serverUrl="https://smz4raj55uxm.usemoralis.com:2053/server">
      <Router />
    </MoralisProvider>
  );
}

export default App;