import React from "react";
import {
    Container,
    Logo,
    ItemsContainer,
    NavItem,
    BackgroundImage,
    Burger,
    ConnectButtonContainer
} from './index.styles'
import { useState, useEffect } from 'react';
import { RewardButton } from "components/UI/atom/button/RewardButton";

import Web3 from 'web3'
import { useDispatch, useSelector } from 'react-redux'
import { setAddress, setRewards, setRoster, setRewardHistory } from 'redux/reducer/userDetailsSlice'
import { BATTLE_ADDRESS, CHAR_ADDRESS } from "helpers/constants";
import CHAR_ABI from "ABI/Characters.json";
import BATTLE_ABI from "ABI/Battle.json";
const Navbar = () => {
    const [isExpanded, toggleExpanded] = useState(false);

    const onWindowResize = () => {
        if(window.innerWidth > 840) {
            if(isExpanded) {
                burgerClick();
            }
        } 
    }
    useEffect(() => {
        window.addEventListener('resize', onWindowResize);
    }, [isExpanded])
    const burgerClick = () => {
        var nav = document.getElementById("navBarBgImage");
        nav.style.height = !isExpanded ? '100vh' : '7rem';

        var body = document.getElementsByTagName("body")[0];
        body.style.overflow = !isExpanded ? 'hidden' : 'unset';

        toggleExpanded(!isExpanded);
    }

    const dispatch = useDispatch();
    const connectedAddress = useSelector(state => state.userDetails.address);
    const rewards = useSelector(state => state.userDetails.rewards);
    const address = useSelector(state => state.userDetails.address);
    const provider = useSelector(state => state.userDetails.provider);
    const [{ metaMaskPresent, metaMaskConnected }, setMetaMaskObject] = useState({
        metaMaskPresent: false,
        metaMaskConnected: false
    });

    const [publicKey, setPublicKey] = useState("");
    const web3 = new Web3(provider == null ? window.ethereum == null ? Web3.givenProvider : window.ethereum : provider);
    let battle_contract = new web3.eth.Contract(BATTLE_ABI, BATTLE_ADDRESS);
    let char_contract = new web3.eth.Contract(CHAR_ABI, CHAR_ADDRESS);

    const getRewards = async(add) => {
        let r = 0;
        if(add != '') {
            let temp = await battle_contract.methods.getRewards(add).call();
            if(temp.length != 0) {
                dispatch(setRewardHistory(temp));
                for(var i = 0; i < temp.length; i++) {
                    r += parseInt(temp[i].amount);
                }
                dispatch(setRewards(r));
            }
        }
        else {
            dispatch(setRewardHistory([]));
            dispatch(setRewards(r));
        }
    }
    const getRoster = async (add) => {
        if(add != '') {
            let rosterDetails = await battle_contract.methods.getRoster(add).call();
            let arr = [];
            if(rosterDetails != null) {
                for(var i = 0; i < rosterDetails.length; i++) {
                    arr.push({
                        charIndex: i,
                        charId: rosterDetails[i].charId,
                        lastAttack: rosterDetails[i].lastAttack,
                        charDetails: await getCharacterDetails(rosterDetails[i].charId)
                    });
                }
                dispatch(setRoster(arr));
            }
        }
        else {
            dispatch(setRoster([]));
        }
    }
    const getCharacterDetails = async(charId) => {
        let details = await char_contract.methods.charInfo(charId).call();
        details.id = charId;
        return details;
    }
    const shorten_pubkey = (pubkey) => {
        let len = pubkey.length;
        let shortkey = pubkey.slice(0, 5) + "....." + pubkey.slice(len - 5, len);
        return shortkey;
    }
    const connectMetaMask = async () => {
        let accounts;
        try {
            if (metaMaskConnected) {
                setMetaMaskObject({ metaMaskConnected: false, metaMaskPresent: false })
                setPublicKey("")
            }
            else {
                await web3?.givenProvider?.request({ method: "eth_requestAccounts" })
                setMetaMaskObject({ metaMaskConnected: true, metaMaskPresent })
                accounts = await web3.eth.getAccounts()
                const account = shorten_pubkey(accounts[0])
                setPublicKey(account)
            }
            let add = '';
            if(accounts) {
                add = accounts[0];
                dispatch(setAddress(accounts[0]));
            }
            else {
                dispatch(setAddress(''));
            }
            await getRewards(add);
            await getRoster(add);

        } catch (error) {
            console.error("metmask error", error);
        }
    };
    useEffect(() => {
        //console.log("hello");
        const isMetaMaskPresent = () => {
            return web3?.givenProvider?.isMetaMask ? true : false;
        };
        setMetaMaskObject(() =>
            isMetaMaskPresent()
                ? { metaMaskPresent: true, metaMaskConnected }
                : { metaMaskPresent: false, metaMaskConnected }
        );
        getRewards(address);
    }, [web3?.givenProvider?.isMetaMask, metaMaskConnected]);

    return (
        <Container>
            <BackgroundImage src="img/app_navbar.png" id="navBarBgImage"/>
            <ItemsContainer>
                <NavItem href="https://defverse.app">
                    <Logo src="img/defverse_logo.png" alt="defverse-logo"/>
                </NavItem>
                <NavItem href="https://defverse.app/tavern">TAVERN</NavItem>
                <NavItem>MARKETPLACE</NavItem>
                <NavItem>HUB</NavItem>
                <NavItem href="https://defverse.app/inventory">INVENTORY</NavItem>
                <NavItem href="/">GAME</NavItem>
                <ConnectButtonContainer>
                    <RewardButton onClick={connectMetaMask}>{connectedAddress == '' ? "Connect" : connectedAddress.substring(0, 5) + "..."}</RewardButton>
                </ConnectButtonContainer>
            </ItemsContainer>
            
            <Burger onClick={() => burgerClick()}>
                |||
            </Burger>
        </Container>
    )
}

export default Navbar;