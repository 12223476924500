export const DVERSE_ADDRESS = "0xe51E087DaBCCA02236287eC7b144782f13d6dbc7";
export const CHAR_ADDRESS = "0xD871124e1EC9109FD2aa847b29d70C143A81703d";
export const BATTLE_ADDRESS = "0x81f151F5281959Faa44Db74E5F9C9C121FD45De7";
export const BURN_ADDRESS = '0x0000000000000000000000000000000000000000';
export const CURRENT_CHAIN = 97;
export const PLACEHOLDER_IMAGE_ID = 'a70f0176-bebe-4da4-1525-d867c6cd1f00';

export const getCDNImage = (images, file) => {
    let imageId = images[file.substring(file.lastIndexOf("/") + 1)];
    const cdnUrl = `https://imagedelivery.net/Vi1yRz5qYPWlkRdLesmlaA/${imageId ? imageId : PLACEHOLDER_IMAGE_ID}/public`;
    return cdnUrl;
}