import "./style.css"
import { BattleDeck } from "components/UI/molecules/Rosters/BattleDeck"
import { CardCollection } from "components/UI/molecules/Rosters/CardCollection"
import { HistoryButton } from "components/UI/atom/button/HistoryButton"
import { useDispatch, useSelector } from "react-redux";
import { rosterClickHandler } from "redux/reducer/rosterClickSlice";
import { setInventory, setRoster } from "redux/reducer/userDetailsSlice";
import { useEffect } from "react";

import { useMoralis } from "react-moralis";
import { CHAR_ADDRESS, BATTLE_ADDRESS, CURRENT_CHAIN } from "helpers/constants";
import CHAR_ABI from "ABI/Characters.json";
import BATTLE_ABI from "ABI/Battle.json";
import Web3 from 'web3';
export const Roster = (props) => {
    const isRoster = useSelector(state => state.rosterClick.value)
    const address = useSelector(state => state.userDetails.address)
    const inventory = useSelector(state => state.userDetails.inventory)
    const roster = useSelector(state => state.userDetails.roster)
    const provider = useSelector(state => state.userDetails.provider)
    const dispatch = useDispatch()
    const { Moralis } = useMoralis();

    const web3 = new Web3(provider == null ? window.ethereum == null ? Web3.givenProvider : window.ethereum : provider);
    let battle_contract = new web3.eth.Contract(BATTLE_ABI, BATTLE_ADDRESS);
    let char_contract = new web3.eth.Contract(CHAR_ABI, CHAR_ADDRESS);

    const getInventory = async () => {
        const res = await Moralis.Web3API.account.getNFTsForContract({
            chain: CURRENT_CHAIN === 97 ? "bsc testnet" : "bsc",
            address: address,
            token_address: CHAR_ADDRESS
        });
        let arr = [];
        if(res != null) {
            for(var i = 0; i < res.total; i++) {
                var c = await getCharacterDetails(res.result[i].token_id);
                arr.push(c);
            }
            dispatch(setInventory(arr))
        }
    }
    const getRoster = async () => {
        let rosterDetails = await battle_contract.methods.getRoster(address).call();
        let arr = [];
        if(rosterDetails != null) {
            for(var i = 0; i < rosterDetails.length; i++) {
                arr.push({
                    charIndex: i,
                    charId: rosterDetails[i].charId,
                    lastAttack: rosterDetails[i].lastAttack,
                    charDetails: await getCharacterDetails(rosterDetails[i].charId)
                });
            }
            dispatch(setRoster(arr));
        }
    }
    const getCharacterDetails = async(charId) => {
        let details = await char_contract.methods.charInfo(charId).call();
        details.id = charId;
        return details;
    }
    useEffect(() => {
        getInventory();
        getRoster();
    }, []);
    return (
        <>
            <div className="container">
                <div className="main">
                    <BattleDeck roster={roster}/>
                    <CardCollection inventory={inventory} />
                </div>
            </div>
            <HistoryButton
                className="d-none d-md-none d-lg-block btn-battle pull-right"
                onClick={() => dispatch(rosterClickHandler())}
            >
                {isRoster > 0 ? "BATTLE" : "ROSTER"}
            </HistoryButton>
            <div className="clearfix" />
        </>
    )
}