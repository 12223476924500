import { useState } from 'react'
import styled from 'styled-components'
import { RewardButton } from '../../atom/button/RewardButton'
import { StateLabelGroup } from './StateLabelGroup'
import { useEffect } from 'react'
import Web3 from 'web3'
import { useDispatch, useSelector } from 'react-redux'
import { setAddress, setRewards, setRoster, setRewardHistory } from 'redux/reducer/userDetailsSlice'

import { BATTLE_ADDRESS, CHAR_ADDRESS } from "helpers/constants";
import CHAR_ABI from "ABI/Characters.json";
import BATTLE_ABI from "ABI/Battle.json";

export const NavRewardsGroup = () => {
    const dispatch = useDispatch();
    const connectedAddress = useSelector(state => state.userDetails.address);
    const rewards = useSelector(state => state.userDetails.rewards);
    const address = useSelector(state => state.userDetails.address);
    const provider = useSelector(state => state.userDetails.provider);
    //const [rewardState, setRewardState] = useState(73892838);

    const [{ metaMaskPresent, metaMaskConnected }, setMetaMaskObject] = useState({
        metaMaskPresent: false,
        metaMaskConnected: false
    });

    const [publicKey, setPublicKey] = useState("");
    const web3 = new Web3(provider == null ? window.ethereum == null ? Web3.givenProvider : window.ethereum : provider);
    let battle_contract = new web3.eth.Contract(BATTLE_ABI, BATTLE_ADDRESS);
    let char_contract = new web3.eth.Contract(CHAR_ABI, CHAR_ADDRESS);
    
    const claimRewards = async(add) => {
        if(add != '') {
            battle_contract.methods.claimUntaxed().send({from: address})
                .on('receipt', () => {
                    alert("Claim successful");
                    getRewards(add);
                })
                .on('error', () => {
                    alert("Unable to claim rewards, please try again");
                })
        }
    }
    const getRewards = async(add) => {
        let r = 0;
        if(add != '') {
            let temp = await battle_contract.methods.getRewards(add).call();
            if(temp.length != 0) {
                dispatch(setRewardHistory(temp));
                for(var i = 0; i < temp.length; i++) {
                    r += parseInt(temp[i].amount);
                }
                dispatch(setRewards(r));
            }
        }
        else {
            dispatch(setRewardHistory([]));
            dispatch(setRewards(r));
        }
    }
    const getRoster = async (add) => {
        if(add != '') {
            let rosterDetails = await battle_contract.methods.getRoster(add).call();
            let arr = [];
            if(rosterDetails != null) {
                for(var i = 0; i < rosterDetails.length; i++) {
                    arr.push({
                        charIndex: i,
                        charId: rosterDetails[i].charId,
                        lastAttack: rosterDetails[i].lastAttack,
                        charDetails: await getCharacterDetails(rosterDetails[i].charId)
                    });
                }
                dispatch(setRoster(arr));
            }
        }
        else {
            dispatch(setRoster([]));
        }
    }
    const getCharacterDetails = async(charId) => {
        let details = await char_contract.methods.charInfo(charId).call();
        details.id = charId;
        return details;
    }

    const shorten_pubkey = (pubkey) => {
        let len = pubkey.length;
        let shortkey = pubkey.slice(0, 5) + "....." + pubkey.slice(len - 5, len);
        return shortkey;
    }

    const connectMetaMask = async () => {
        let accounts;
        try {
            if (metaMaskConnected) {
                setMetaMaskObject({ metaMaskConnected: false, metaMaskPresent: false })
                setPublicKey("")
            }
            else {
                await web3?.givenProvider?.request({ method: "eth_requestAccounts" })
                setMetaMaskObject({ metaMaskConnected: true, metaMaskPresent })
                accounts = await web3.eth.getAccounts()
                const account = shorten_pubkey(accounts[0])
                setPublicKey(account)
            }
            let add = '';
            if(accounts) {
                add = accounts[0];
                dispatch(setAddress(accounts[0]));
            }
            else {
                dispatch(setAddress(''));
            }
            await getRewards(add);
            await getRoster(add);

        } catch (error) {
            console.error("metmask error", error);
        }
    };

    useEffect(() => {
        const isMetaMaskPresent = () => {
            return web3?.givenProvider?.isMetaMask ? true : false;
        };
        setMetaMaskObject(() =>
            isMetaMaskPresent()
                ? { metaMaskPresent: true, metaMaskConnected }
                : { metaMaskPresent: false, metaMaskConnected }
        );
        getRewards(address);
    }, [web3?.givenProvider?.isMetaMask, metaMaskConnected]);

    return (
        <Body>
            <RewardLabel>
                <StateLabelGroup
                    color="#24FF00"
                    title="Reward"
                    count={rewards/(10**18)}
                />
            </RewardLabel>

            <RewardButton onClick={claimRewards}>Claim Reward</RewardButton>
            {/*<RewardButton onClick={connectMetaMask}>{connectedAddress == '' ? "Connect" : "Connected"}</RewardButton>*/}
            {/* <Space/> */}
            {/* <RewardButton onClick={() => connectMetaMask()}>
                {(metaMaskPresent && !metaMaskConnected) ? "Connect" : publicKey}
            </RewardButton> */}
        </Body>
    )
}

const RewardLabel = styled.div`
    background: url('./img/Assets/reward-button-1.png') no-repeat 50%;
    background-size: 100% 100%;
    padding: 14px 25px 8px;
    margin-right: 18px;
    @media (max-width: 991px) {
        padding: 8px 25px 6px;
    }

    @media (max-width: 575px) {
        padding: 5px 15px 3px;
    }

    @media (max-width: 479px) {
        padding: 0px !important;
        background: none;
        margin: 0px;
    }
`;

const Body = styled.div`
    background: url('./img/Assets/rewards-label.png') no-repeat;
    background-size: 100% 100%;
    width: auto;
    height: auto;
    display: inline-flex;
    align-items: center;
    padding: 14px 28px 14px 14px;
    gap: 1rem;
    label {
        font-size: 24px;
    }

    @media (max-width: 991px) {
        width: 70%;
        justify-content: space-between;
    }

    @media (max-width: 780px) {
        width: 90%;
        label {
            font-size: 18px;
        }
    }


    @media (max-width: 479px) {
        width: 100%;
        
        button, label {
            font-size: 16px;
        }
    }
    
    @media (max-width: 420px) {
        flex-flow: column;
    }
`

const Space = styled.label`
    width: 10px;
`