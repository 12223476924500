import React from 'react'
import Slider from 'react-slick'
import { Arrow } from "components/UI/atom/button/ArrowButton"
import { Monstercard } from "../../MonsterCard/MonsterCard"
import { FightButton } from 'components/UI/atom/button/FightButton'
import "./style.css"
import Card from '../../Card';
import { setCurrentCharacter, toggleLoadingScreen } from 'redux/reducer/userDetailsSlice'
import { useDispatch } from 'react-redux'
import BATTLE_ABI from "ABI/Battle.json"
import { BATTLE_ADDRESS } from "helpers/constants"
import Web3 from 'web3';
import { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { getWinChance } from "../../Card/helper"
import { setCurrentEnemies } from 'redux/reducer/userDetailsSlice'
export const BaseSlider = (props) => {
    //const [enemies, setEnemies] = useState([0, 0, 0, 0])
    const currentChar = useSelector(state => state.userDetails.currentCharacter)
    const currentRoster = useSelector(state => state.userDetails.roster)
    const address = useSelector(state => state.userDetails.address)
    const provider = useSelector(state => state.userDetails.provider)
    const [accuracy, setAccuracy] = useState(0)
    const enemies = useSelector(state => state.userDetails.currentEnemies)
    const dispatch = useDispatch();

    const web3 = new Web3(provider == null ? window.ethereum == null ? Web3.givenProvider : window.ethereum : provider);
    let battle_contract = new web3.eth.Contract(BATTLE_ABI, BATTLE_ADDRESS);

    const getEnemies = async () => {
        let enemyArray = []
        for(var i = 0; i < 4; i++) {
            var e = await battle_contract.methods.enemies(i).call();
            if(e) {
                enemyArray.push(e);
            }
        }
        dispatch(setCurrentEnemies(enemyArray));
    }

    useEffect(() => {
        if(props.isEnemy) {
            if(enemies[0] == 0) {
                getEnemies();
            }
            if(address != '') {
                if(currentRoster.length != 0) {
                    var currChar = currentRoster[currentChar];
                    setAccuracy(currChar.charDetails.accuracy);
                }
            }
        }
    }, [address])

    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    const handleResize = (e) => {
        setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    const items = props.monsters
    const roster = props.roster
    const settings = {
        className: "center",
        centerMode: true,
        centerPadding: '33%',
        dots: true,
        infinite: true,
        speed: 300,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        breakpoint: 600,
        customPaging: function (i) {
            return <div className="dot"></div>;
        },
        appendDots: dots => (
            <div>
              <ul className="slick-dots slick-thumb">
                {dots}
              </ul> 
            </div>
        ),
        customPaging: i => (
            <button />
        ),
    };
    let i = 0;
    return (
        <Slider   
            {...settings}
            nextArrow={<Arrow type="next" />}
            prevArrow={<Arrow type="prev" />}
            afterChange={
                !props.isEnemy && ((curr) => {
                    dispatch(setCurrentCharacter(curr));
                })
            }
        >
        {
            props.isEnemy ? (
                items.map(item => (
                    <div id="cont" key={i}>
                        <Card level={item.rate} isEnemyCard={true} isAnimated={false} width={windowWidth * 0.25} defenseProps={{
                            defense: enemies[i],
                            chance: getWinChance(accuracy, enemies[i++])
                        }}/> 
                        {!props.hidebtn ? (<FightButton enemy={item.rate}>Fight!!!</FightButton>): ("")}
                    </div>
                ))
            ) : ( 
                roster.map(item => (
                    <div id="cont" key={i++}>
                        <Card level={item.charDetails.level} 
                            isEnemyCard={false} 
                            isAnimated={false} 
                            name={item.charDetails.name} 
                            power={item.charDetails.power} 
                            accuracy={item.charDetails.accuracy} 
                            rarity={item.charDetails.rarity}
                            width={windowWidth * 
                            (
                                windowWidth > 1240 ? 0.1 : 
                                windowWidth > 1024 ? 0.12 : 0.25
                            )
                        }/> 
                    </div>
                ))
            )
        }


            {/*items.map(item => (
                props.isEnemy ? (
                    <div id="cont" key={i++}>
                        <Card level={item.rate} isEnemyCard={true} isAnimated={false} width={windowWidth * 0.25}/> 
                        {!props.hidebtn ? (<FightButton>Fight</FightButton>): ("")}
                    </div>
                ) : (
                    
                    <div id="cont" key={i++}>
                        <Card level={item.rate} isEnemyCard={true} isAnimated={false} width={windowWidth * 
                            (
                                windowWidth > 1240 ? 0.1 : 
                                windowWidth > 1024 ? 0.12 : 0.25
                            )
                        }/> 
                        {!props.hidebtn ? (<FightButton>Fight</FightButton>): ("")}
                    </div>
                )
                
                    ))*/}
        </Slider>
    )
}