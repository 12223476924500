const images = {
    'red-button.png': 'ac64647a-7642-4a40-0b9e-ab4ba4345400',
    'fight-button.png': '9c02aab8-67dc-4f44-1222-671609bbef00',
    'green-button.png': '6dfccdeb-b1bb-4f3c-8df0-c4795dab1200',
    'legendary_emblem.webp': '82a800f8-b23f-497d-664c-d77ce831b800',
    'uncommon_emblem.webp': '7fa34bab-554a-4afe-ef0d-ecc939d69c00',
    'rare_emblem.webp': '556a5626-6774-4bd6-f449-64a157065f00',
    'common_emblem.webp': '91f8ace0-ff9e-40b4-ed7d-8f356e42ac00',
    'monster-id.webp': 'b13a8d34-0775-45e6-9cb1-a9c80fcf3f00',
    'boder_01_dragon.webp': '40955b20-d921-4069-cfaa-c82377271a00',
    'enemy-border-2.webp': '9df1a677-5f7f-4abc-9cfb-b174ec951300',
    'boder_03_ninja.webp': 'a372302d-17ec-4e2c-1d45-d0f68cca1400',
    'boder_01_robot.webp': '5030dca2-0575-44e3-9fc8-a76cbc6ace00',
    'enemy-border-4.webp': 'e9424487-6dcb-4265-9749-4520511b3600',
    'boder_03_holi.webp': '85358f9e-7fc4-4ed5-0067-07ecf27ec100',
    'enemy-border-1.webp': 'ac77d22f-5664-4d01-cff3-d2626ea03600',
    'boder_03_hell.webp': 'f74e7fa9-762f-47a7-29e2-4d58b2745300',
    'enemy-border-3.webp': '8bb7e3e8-03ae-4b15-5ad9-5314cd495800',
    'boder_03_fairy.webp': '97c7e945-a7a9-4e10-9d4d-e28d4f0f3800',
    'boder_03_robot.webp': '3d990632-0624-46b3-d098-1ee8645b2f00',
    'boder_btuin.webp': '7f49a13d-3c14-480f-cbbf-34f102684700',
    'boder_01_ninja.webp': 'ab2ace35-2053-4d84-64c6-2f68ae407100',
    'boder_03_dragon.webp': '5b6ecc65-6e70-4d95-42ff-2b39a3cfc200',
    'boder_02_fairy.webp': '905ac2ea-b8fa-4c4a-a39d-346cf97c9900',
    'boder_01_hell.webp': '57004ab1-8f3a-4f55-a1e6-b6bf065ce200',
    'boder_02_hell.webp': 'd1071c75-9d5e-4702-dbab-03db02943100',
    'boder_01_fairy.webp': 'c76e3d3d-9536-464c-0b4f-c82f99bf8400',
    'boder_01_holi.webp': 'eaef0f59-bf40-47a0-2113-7f3bc00c2800',
    'boder_02_dragon.webp': '12ca458d-fe02-4016-daea-91a974ac0700',
    'boder_02_ninja.webp': '4be56f6f-ce16-4f93-0af8-1790fae15600',
    'boder_02_robot.webp': '1b3ee175-c2e9-425d-4e25-20f688f6c400',
    'boder_02_holi.webp': '03c7e161-c59b-4969-eec1-3a190efe8a00',
    'anim_cat_dragon_02.webp': '009b13e6-c850-4cb5-962f-a1cac73ee000',
    'anim_cat_ninja_03.webp': '1da0e888-a607-447e-a476-66ad9ec78600',
    'anim_cat_robot_03.webp': 'ccaab338-73dc-4f29-482c-27dd9b6a8600',
    'anim_cat_hell_03.webp': '4a5755ae-3288-42f6-96d2-e6437ea22e00',
    'anim_cat_fairy_03.webp': '0037c405-a957-44dc-0b13-6d539409ac00',
    'anim_cat_dragon_03.webp': 'ab768886-2628-4356-e32a-f93c07594d00',
    'anim_cat_holi_02.webp': '5dd421a0-63ce-488b-9182-806ea35cef00',
    'anim_cat_fairy_01.webp': '8a930a6a-e967-42cb-a53b-04b5f100a200',
    'anim_cat_ninja_02.webp': '4f14286a-3dfc-4bec-fbf1-ca687e45dc00',
    'anim_cat_hell_02.webp': 'a3604c84-9c00-4ad3-8e32-9ed12a1ecf00',
    'anim_cat_hell_01.webp': '04085065-83cc-42a5-1c60-42ce250e6000',
    'anim_cat_holi_03.webp': '6b084170-f6f8-4b18-ad70-5cf2eb607300',
    'anim_cat_ninja_01.webp': '029a26fc-335f-4fac-fac5-ee9066c28300',
    'anim_cat_holi_01.webp': '6b2c617d-6060-43af-0d56-f0d08a079100',
    'anim_cat_fairy_02.webp': 'a1f2b5ee-34a3-4539-6366-8fad91029f00',
    'anim_cat_robot_02.webp': '760b70ba-e1be-4168-448f-381d683ad600',
    'anim_cat_robot_01.webp': 'ce858809-be7d-4cc9-0936-a691c6454500',
    'defverse_logo.png': 'a70f0176-bebe-4da4-1525-d867c6cd1f00',
    'enemy_avatar_4.webp': '47e9b208-2c28-47ac-88c3-aa2e1402d300',
    'enemy_avatar_3.webp': '1910ca26-4532-49e5-9431-a8ff73c52500',
    'enemy_avatar_2.webp': 'a639d910-70b5-485b-fada-2eec335e0500',
    'enemy_avatar_1.webp': '36a85481-f5c2-4a7d-8e90-4e4dc36d5d00',
    'anim_cat_dragon_01.webp': 'dade0e90-bb8b-4faa-18a4-40813b058800',
    'anim_btuin.webp': 'a5df458d-7281-4f36-d57f-e6a0a37d2b00'
}

module.exports.images = images;