import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Layout } from '../components/templates/Layout';
import Home from '../components/pages/Home'
import { useMoralis } from 'react-moralis';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setProvider, setImages } from 'redux/reducer/userDetailsSlice';
import axios from 'axios';
import { images } from 'data/image_data';
export const Router = () => {
  const dispatch = useDispatch();
  const { Moralis } = useMoralis();
  
  const initWeb3 = async () => {
    var temp = await Moralis.enableWeb3();
    dispatch(setProvider(Moralis.provider));
  }
  /*const axiosGetImages = () => {

    let url = "https://cors-anywhere.herokuapp.com/https://api.cloudflare.com/client/v4/accounts/e74175ca091bb9b32b73b60449316903/images/v1?per_page=100";
    let apiToken = "h_5ttgVOe4BdtybJAc16m6U5xPzU-YofDk1DKCFv";
    let config = {
      headers: {
        "Authorization": `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      }
    }

    axios.get(url, config)
    .then((res) => {
      let data = res.data.result.images;

      let images = {};
      for(let x of data) {
        images[x.filename] = x.id;
      }
      dispatch(setImages(images));
    })
    .catch((err) => {
      console.log(err);
    });
  }*/
  useEffect(async() => {
    await initWeb3();
    dispatch(setImages(images));
  },[])
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={
          <Layout>
            <Home />
          </Layout>
        } />
      </Routes>
    </BrowserRouter>
  );
}