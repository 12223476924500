import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    z-index: 2;
    overflow: hidden;
`;
export const BackgroundImage = styled.img`
    width: 100%;
    height: 7rem;
    top: 0;
    left: 0;
    transition: 500ms;
`;
export const ItemsContainer = styled.div`
    position: absolute;
    display: flex;
    gap: 2.5rem;
    align-items: center;
    width: 90%;
    left: 5%;
    top: 1rem;
    overflow: hidden;
    transition: 500ms;
    @media(max-width: 840px) {
        flex-direction: column;
        align-items: start;
    }
`;
export const Logo = styled.img`
    width: 5rem;
`;
export const NavItem = styled.a`
    font-family: 'AgencyFB-Bold' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    font-size: 1.3rem;
    text-decoration: none;
    
    &:hover {
        color: yellow;
        cursor: pointer;
    }
`;
export const Burger = styled.a`
    position: absolute;
    height: 5rem;
    width: 0rem; 
    top: 1rem;
    right: 5%;
    font-weight: bold;
    text-decoration: none;
    font-size: 3rem;
    text-align: center;
    color: white;
    letter-spacing: 0.2rem;
    transform: rotate(90deg);
    transition: 500ms;
    overflow: hidden;
    &:hover {
        cursor: pointer;
        color: yellow;
    }
    @media(max-width: 840px) {
        width: 5rem;
    }
`;
export const ConnectButtonContainer = styled.div`
    height: 5rem;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 1rem;
`;