import React, { useState } from "react";
import CardBorder from "components/UI/atom/cardborder";
import CardAvatar from "components/UI/atom/CardAvatar";
import {
    CardContainer,
    CardComponentsConatiner,
    CardEnemyStats,
    CardRarityContainer,
    EmblemImage,
    EmblemText,
    EmblemTextContainer,
    CardStats
} from './index.styles';
import { CardHelper } from "./helper";
import { getCDNImage } from "helpers/constants";
import { useSelector } from "react-redux";
const Card = ({isEnemyCard, isAnimated, level, width, name = null, borderClass = null, avatarClass = null, defenseProps = {
        defense: 0,
        chance: 0
    }, rarity = "", power = 0, accuracy = 0}) => {
    const images = useSelector(state => state.userDetails.images)
    const helper = new CardHelper({
        isEnemyCard: isEnemyCard, 
        isAnimated: isAnimated, 
        level: level, 
        width: width, 
        name: name, 
        borderClass: borderClass, 
        avatarClass: avatarClass, 
        defenseProps: defenseProps
    });
    /*if(defenseProps == null) {
        const range = {
            1: [15, 30],
            2: [31, 45],
            3: [46, 60],
            4: [70, 80]
        }
        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
        }
        defenseProps = {
            defense: getRandomInt(range[level][0], range[level][1] + 1),
            chance: 69
        }
    }*/
    return (
      <CardContainer style={{width: width, height: width * 1.67}}>
          <CardComponentsConatiner>
              <CardBorder isAnimated={isAnimated && !isEnemyCard} file={helper.borderSelector()} width={width} borderClass={helper.borderClassSelector()}></CardBorder>
              <CardAvatar isAnimated={isAnimated} file={helper.avatarSelector()} width={width} avatarClass={helper.avatarClassSelector()} isEnemy={isEnemyCard} level={level}></CardAvatar>
              {
                  isEnemyCard ? (
                    <CardEnemyStats style={{top: width * 1.33, lineHeight: width * 1.67 * 0.005, fontSize: width * 0.06}}>
                        Defense: {defenseProps.defense} ({defenseProps.chance}%)
                    </CardEnemyStats>
                  ) : (
                    <>
                    
                    <CardStats style={{top: width * (
                        level == 1 ? 1.205 :
                        level == 2 ? 1.205 :
                        level == 3 ? 1.245 : 1.185
                    ), lineHeight: width * 1.67 * 0.005, fontSize: width * 0.06}}>
                        <span>{power}</span>
                        <span>{accuracy}</span>
                    </CardStats>
                    <CardRarityContainer style={{top: width * (
                        level == 1 ? 1.31 :
                        level == 2 ? 1.31 :
                        level == 3 ? 1.35 : 1.3
                    ), lineHeight: width * 1.67 * 0.005, fontSize: width * 0.06}}>
                        <EmblemImage src={getCDNImage(images, "img/Assets/" + (rarity == 0 ? 'common' : rarity == 1 ? 'uncommon' : rarity == 2 ? 'rare' : 'legendary') + "_emblem.webp")} alt="rarity_emblem"/>
                        <EmblemTextContainer>
                            <EmblemText>{rarity == 0 ? 'COMMON' : rarity == 1 ? 'UNCOMMON' : rarity == 2 ? 'RARE' : 'LEGENDARY'}</EmblemText>
                        </EmblemTextContainer>
                    </CardRarityContainer>
                    </>
                  )
              }
          </CardComponentsConatiner>
      </CardContainer>  
    );
}

export default Card;