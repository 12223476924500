import styled, { keyframes } from 'styled-components';
import { useSelector } from 'react-redux';
import CardAvatar from 'components/UI/atom/CardAvatar';
import { CardHelper } from 'components/UI/molecules/Card/helper';
import { useEffect, useState } from 'react';
import { StarBadge } from 'components/UI/atom/badge/StarBadge';
import { toggleLoadingScreen } from 'redux/reducer/userDetailsSlice';
import { setReset } from 'redux/reducer/fightOutcomeSlice';
import { useDispatch } from 'react-redux';
export const LoadingSlate = () => {
    const currentEnemy = useSelector(state => state.userDetails.currentEnemy);
    const currentCharacter = useSelector(state => state.userDetails.currentCharacter);
    const roster = useSelector(state => state.userDetails.roster);
    const reward = useSelector(state => state.fightOutcome.rewards);
    const fightStatus = useSelector(state => state.fightOutcome.status);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [charDetails, setCharDetails] = useState(null);
    const [charHelper, setCharHelper] = useState(null);
    const dispatch = useDispatch();
    const hideLoading = () => {
        dispatch(toggleLoadingScreen(0));
        dispatch(setReset());
    }
    const handleResize = (e) => {
        setWindowWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        if(roster.length != 0) {
            setCharDetails(roster[currentCharacter].charDetails);
            setCharHelper(new CardHelper({
                isEnemyCard: false, 
                isAnimated: true, 
                level: roster[currentCharacter].charDetails.level, 
                width: windowWidth * 0.4, 
                name: roster[currentCharacter].charDetails.name
            }));
        }
    }, [])
    const enemyHelper = new CardHelper({
        isEnemyCard: true, 
        isAnimated: true, 
        level: currentEnemy, 
        width: windowWidth * 0.4
    });
    return (    
        <LoadingSlateContainer>
            <VSContainer>
                <span><span>V</span></span>
                <span><span>S</span></span>
            </VSContainer>
            <CenterRibbon>
                <EnemyStatsContainer>
                    <StarBadge
                        rate={currentEnemy}
                        limit="4"
                        activeColor="#fff"
                        blankColor="#042e7e"
                    />
                    <span>{enemyHelper.enemyName()}</span>
                </EnemyStatsContainer>
                <CharStatsContainer>
                    <StarBadge
                        rate={charDetails ? charDetails.level : 0}
                        limit="4"
                        activeColor="#fff"
                        blankColor="#042e7e"
                    />
                    <span>{charDetails ? charDetails.name : ""}</span>
                </CharStatsContainer>
            </CenterRibbon>
            {
                fightStatus == 1 ? 
                /*<WinRibbon>
                    YOU WIN<br/><span>{reward/(10**18)}&nbsp;DVERSE tokens<br/><span onClick={() => hideLoading()}>Click here to continue</span></span>
                </WinRibbon>*/
                <PopUpResultContainer>
                    <PopUpWinBackground>
                        <PopupMessageContainer>
                            <PopUpHeader>YOU WIN!</PopUpHeader>
                            <PopUpRewardMessage>{reward/(10**18)}&nbsp;DVERSE</PopUpRewardMessage>
                            <ExitButton onClick={() => hideLoading()}>Close</ExitButton>
                        </PopupMessageContainer>
                    </PopUpWinBackground>
                </PopUpResultContainer> :
                fightStatus == -1 ?
                <PopUpResultContainer>
                    <PopUpLoseBackground>
                        <PopupMessageContainer>
                            <PopUpHeader>YOU LOSE</PopUpHeader>
                            <ExitButton onClick={() => hideLoading()}>Close</ExitButton>
                        </PopupMessageContainer>
                    </PopUpLoseBackground> 
                </PopUpResultContainer>: null
            }
            <EnemyAvatarConatiner>
                <CardAvatar isAnimated={true} 
                    file={enemyHelper.avatarSelector()} 
                    width={windowHeight * (windowWidth > 1000 ? 0.5 : 0.4)} 
                    avatarClass={enemyHelper.avatarClassSelector()} 
                    isEnemy={true} 
                    level={currentEnemy}
                />
            </EnemyAvatarConatiner>
            <CharAvatarConatiner>
                {
                    charDetails && 
                    <CardAvatar isAnimated={true} 
                        file={charHelper.avatarSelector()} 
                        width={windowHeight * (windowWidth > 1000 ? 0.5 : 0.4)} 
                        avatarClass={charHelper.avatarClassSelector()} 
                        isEnemy={false} 
                        level={charDetails.level }
                    />
                }
                
            </CharAvatarConatiner>
        </LoadingSlateContainer>
    )
}
const ShowLoadingAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity 1;
    }
`;
const FromTopToBottom = keyframes`
    from {
        top: -100vh;
    }
    to {
        top: 0;
    }
`;
const FromBottomToTop = keyframes`
    from {
        bottom: -100vh;
    }
    to {
        bottom: 0;
    }
`;
const FromLeftToRight = keyframes`
    from {
        left: -100vw;
    }
    to {
        left: 0;
    }
`;
const FromRightToLeft = keyframes`
    from {
        left: 100vw;
    }
    to {
        left: 0;
    }
`;
const Disappering = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;
const LoadingSlateContainer = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    margin: 0;
    left: 0;
    top: 0;
    background-color: rgb(1,32,147, 0.5);
    overflow: hidden;
    display: flex;
    animation: ${ShowLoadingAnimation} 1s linear 1 forwards;
`;
const EnemyAvatarConatiner = styled.div`
    left: 0;
    top: -100vh;
    position: absolute;
    width: 50vw;
    height: 100vh;
    animation: ${FromTopToBottom} 1s ease-in 1.2s 1 forwards;
    & > * {
        top: unset !important;
        bottom: calc(50vh) !important;
        position: absolute;
        z-index: 2;
        @media(max-width: 1000px) {
            bottom: unset !important;
            top: -5% !important;
            left: 5%;
        }
    }
`;
const CharAvatarConatiner = styled.div`
    right: 0;
    bottom: -100vh;
    position: absolute;
    width: 50vw;
    height: 100vh;
    animation: ${FromBottomToTop} 1s ease-in 1.2s 1 forwards; 
    & > * {
        bottom: unset !important;
        top: calc(50vh - 8rem) !important;
        left: unset !important;
        right: 0 !important;
        position: absolute;
        z-index: 2;
        @media(max-width: 1000px) {
            top: unset !important;
            bottom: 0 !important;
            right: 5%;
        }
    }
`;
const CenterRibbon = styled.div`
    left: -100vw;
    background-color: #fbbe00;
    position: absolute;
    width: 100vw;
    height: 16rem;
    top: calc(50vh - 8rem);
    animation: ${FromLeftToRight} 1s ease-in 2.5s 1 forwards; 
    
`
const VSContainer = styled.div`
    position: absolute;
    color: #d00635;
    font-size: 10vw;
    height: 100%;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > :nth-child(1) {
        left: -100vw;
        position: relative;
        animation: ${FromLeftToRight} 1s ease-in 3.5s 1 forwards; 
    }
    & > :nth-child(2) {
        left: 100vw;
        position: relative;
        animation: ${FromRightToLeft} 1s ease-in 3.5s 1 forwards; 
    }
    @media(max-width: 1000px) {
        font-size: 15vw;
    }
`;
const EnemyStatsContainer = styled.div`
    left: 0;
    position: absolute;
    bottom: 0;
    padding: 1rem 0rem;
    font-size: 3vw;
    width: 40%;
    text-align: center;
    > * {
        margin: 0 0.5rem;
    }
    @media(max-width: 1000px) {
        font-size: 8vw;
    }
    @media(max-width: 480px) {
        bottom: unset !important;
        top: 0;
        width: 100%;
        font-size: 10vw;
    }
`;
const CharStatsContainer = styled.div`
    right: 0;
    position: absolute;
    top: 0;
    padding: 1rem 0rem;
    font-size: 3vw;
    width: 40%;
    text-align: center;
    > * {
        margin: 0 0.5rem;
    }
    @media(max-width: 1000px) {
        font-size: 8vw;
    }
    @media(max-width: 480px) {
        top: unset !important;
        bottom: 0;
        width: 100%;
        font-size: 10vw;
    }
`;
const PopUpResultContainer = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0,0,0,0.5);
    z-index: 10;
`
const PopUpBackground = styled.div`
    position: fixed;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 10;
    @media(max-width: 768px) {
        left: 10%;
        width: 80%;
    }
    @media(max-width: 480px) {
        left: 5%;
        width: 90%;
    }
    animation: ${ShowLoadingAnimation} 1s linear 1 forwards;
`;
const PopUpWinBackground = styled(PopUpBackground)`
    background-image: url("/img/win-bg.png");
`;
const PopUpLoseBackground = styled(PopUpBackground)`
    background-image: url("/img/lose-bg.png");
`;
const PopupMessageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const PopUpHeader = styled.h1`
    font-size: 4rem;
    color: white;
    @media(max-width: 768px) {
        font-size: 2rem;
    }
`;
const PopUpRewardMessage = styled.span`
    font-size: 2rem;
    color: white;
    @media(max-width: 768px) {
        font-size: 1rem;
    }
`;
const ExitButton = styled.span`
    font-size: 1rem;
    margin-top: 2rem;
    color: #fbbe00;
    animation: ${Disappering} 1s infinite alternate; 
    &:hover {
        cursor: pointer;
    }
`;