import Modal from "react-modal"
import { StateLabelGroup } from "../LabelGroup/StateLabelGroup"
import { CloseButton } from "components/UI/atom/button/CloseButton"
import { MyScrollbar } from "../Scrollbar"
import "./style.css"
import { useSelector } from "react-redux"

export const HistoryModal = (props) => {
    const closeModal = () => {
        props.toggleModal(false)
    }
    const history = useSelector(state => state.userDetails.rewardHistory);
    const header = { title: "FIGHT HISTORY", text: Math.min(history.length, 10) + " WINS" }
    const contents = [];
    for(var x = history.length - 1; x >= 0; x--) {
        var date = new Date(history[x].timeRewarded * 1000);
        contents.push({
            id: "ID",
            id_value: (x + 1) + " (" + date.getDate() + "/" + (date.getMonth() + 1) + ") " + date.getHours() + ":" + date.getMinutes(),
            status: history[x].amount == 0 ? "LOSE" : "WIN",
            status_value: (history[x].amount/10**18) + " DVERSE"
        })
    }

    const customStyles = {
        content: {
            border: 'solid 3px #fff',
            borderRadius: '20px',
            backgroundColor: 'rgb(0, 0, 0, 0.85)',
            backgroundImage: '-moz - linear - gradient(90deg, rgb(0, 0, 0) 0 %, rgb(1, 39, 97) 100 %)',
            backgroundImage: '-webkit - linear - gradient(90deg, rgb(0, 0, 0) 0 %, rgb(1, 39, 97) 100 %)',
            backgroundImage: '-ms - linear - gradient(90deg, rgb(0, 0, 0) 0 %, rgb(1, 39, 97) 100 %)',
            left: '40px',
            top: '120px',
            maxWidth: '350px',
            maxHeight: '600px',
            overflow: 'visible',
        },
    }
    let i = 0;
    return (
        <Modal
            isOpen={props.isOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={() => closeModal()}
            style={customStyles}
            ariaHideApp={false}
        >
            <CloseButton onClick={closeModal} />
            <StateLabelGroup
                title={header.title}
                titleColor="#00fffc"
                count={header.text}
                className="history-modal-header"
            />
            <MyScrollbar>
                {contents.map(content => (
                    <div className="item" key={i++}>
                        <StateLabelGroup
                            title={content.id}
                            count={content.id_value}
                        />
                        <StateLabelGroup
                            title={content.status}
                            count={content.status_value}
                            titleColor={content.status === "LOSE" ? "#6a0d0d" : "#24ff00"}
                        />
                    </div>
                ))}
            </MyScrollbar>
        </Modal>
    )
}