import styled from "styled-components";
import { StateLabelGroup } from "./StateLabelGroup";
import { HistoryButton } from "components/UI/atom/button/HistoryButton";
import { useDispatch, useSelector } from "react-redux";
import { rosterClickHandler } from "redux/reducer/rosterClickSlice";
import { getStackCount } from "../Card/helper";
export const AttrLabelGroup = (props) => {
    const isRoster = useSelector(state => state.rosterClick.value)
    const address = useSelector(state => state.userDetails.address)
    const roster = useSelector(state => state.userDetails.roster);
    const currentCharacter = useSelector(state => state.userDetails.currentCharacter);
    const dispatch = useDispatch()
    let lastAttack = roster.length == 0 ? "" : new Date(roster[currentCharacter].lastAttack * 1000);
    return (
        <>
            <Body>
                <div className="d-none d-md-none d-lg-block labelgroup">
                    <StateLabelGroup
                        textColor="#e02222"
                        title="Last Attack"
                        count={roster.length == 0 ? "" : lastAttack.getTime() == 0 ? "N/A"  :
                            lastAttack.getDate() + "/" + (lastAttack.getMonth() + 1) + "/" + lastAttack.getFullYear() + " " + lastAttack.getHours() + ":" + lastAttack.getMinutes()
                        }
                    />
                    <StateLabelGroup
                        title="Stack"
                        count={roster.length == 0 ? 0 : getStackCount(roster[currentCharacter].lastAttack) }
                    />
                </div>
                <div className="d-block d-md-block d-lg-none labelgroup-mobile">
                    <StateLabelGroup
                        titleColor="#f00"
                        textColor="#fff"
                        title="Last Attack"
                        count={roster.length == 0 ? "" : lastAttack.getTime() == 0 ? "N/A" :
                            lastAttack.getDate() + "/" + (lastAttack.getMonth() + 1) + "/" + lastAttack.getFullYear() + " " + lastAttack.getHours() + ":" + lastAttack.getMinutes()
                        }
                    />
                    <StateLabelGroup
                        titleColor="#0f0"
                        textColor="#fff"
                        title="Stack"
                        count={roster.length == 0 ? 0 : getStackCount(roster[currentCharacter].lastAttack) }
                    />
                </div>
                {
                    isRoster > 0 || address != '' ? (
                        <HistoryButton
                            className="d-none d-md-none d-lg-block"
                            onClick={() => dispatch(rosterClickHandler())}
                        >
                            {isRoster > 0 ? "BATTLE" : "ROSTER"}
                        </HistoryButton>
                    ) : null
                }
            </Body>
        </>
    )
}

const Body = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    bottom: 50px;
    .labelgroup {
        background: url("./img/Assets/claim-label.png") no-repeat;
        background-size: 100% 100%;
        padding: 20px;
        width: 400px;
    }

    @media (max-width: 991px) {
        position: relative;
        bottom: 0px;
        text-algin: center;
        .labelgroup-mobile {
            margin: auto;
            display: flex !important;
            div {
                margin: 12px;
            }
            label {
                font-size: 24px;
            }
        }
    }

    @media (max-width: 320px) {
        .labelgroup-mobile {
            display: block !important;
            label {
                font-size: 20px;
            }
        }
    }
`;