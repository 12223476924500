import { createSlice } from "@reduxjs/toolkit"

export const userDetailsSlice = createSlice({
    name: 'userDetails',
    initialState: {
        address: '',
        inventory: [],
        roster: [],
        rewards: 0,
        lastClaim: '',
        nextClaim: '',
        currentCharacter: 0,
        currentEnemy: 0,
        isLoadingScreenVisible: false,
        rewardHistory: [],
        currentEnemies: [0,0,0,0],
        provider: null,
        images: {}
    },
    reducers: {
        setInventory: (state, inv) => {
            state.inventory = inv.payload;
        },
        setRoster: (state, ros) => {
            state.roster = ros.payload;
        },
        setAddress: (state, add) => {
            state.address = add.payload;
        },
        setRewards: (state, r) => {
            state.rewards = r.payload;
        },
        addReward: (state, r) => {
            state.rewards += r.payload;
        },
        setLastClaim: (state, l) => {
            state.lastClaim = l;
        },
        setNextClaim: (state, l) => {
            state.nextClaim = l;
        },
        setCurrentCharacter: (state, c) => {
            state.currentCharacter = c.payload;
        },
        toggleLoadingScreen: (state, enemy) => {
            state.currentEnemy = enemy.payload;
            state.isLoadingScreenVisible = !state.isLoadingScreenVisible;
        },
        setRewardHistory: (state, h) => {
            state.rewardHistory = h.payload;
        },
        setCurrentEnemies: (state, h) => {
            state.currentEnemies = h.payload;
        },
        setProvider: (state, h) => {
            state.provider = h.payload;
        },
        setImages: (state, h) => {
            state.images = h.payload;
        }
    }
})

export const { 
    setInventory, 
    setRewards, 
    setRoster, 
    setAddress, 
    setLastClaim, 
    setNextClaim, 
    setCurrentCharacter, 
    toggleLoadingScreen, 
    addReward,
    setRewardHistory,
    setCurrentEnemies,
    setProvider,
    setImages
 } = userDetailsSlice.actions

export default userDetailsSlice.reducer