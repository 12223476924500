import { CardSlice } from "components/UI/organisms/Sliders"
import { Roster } from "components/UI/organisms/Roster"
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Home = () => {
    const isRoster = useSelector(state => state.rosterClick.value)
    useEffect(() => {
        document.title = "DefVerse | " + (isRoster < 0 ? "Battle" : "Roster");
    })
    return (
        (isRoster < 0 ?
            <CardSlice />
            :
            <Roster />
        )
    )
}

export default Home