import styled from "styled-components"
import { StateLabelGroup } from "../LabelGroup/StateLabelGroup"
import { HistoryButton } from "components/UI/atom/button/HistoryButton"
import { StarBadge } from "components/UI/atom/badge/StarBadge"
import { useDispatch, useSelector } from "react-redux"
import { deployBattle, removeBattle } from "redux/reducer/rostersCounterSlice"
import { setInventory, setRoster } from "redux/reducer/userDetailsSlice"
import CardAvatar from "components/UI/atom/CardAvatar"
import { CardHelper } from "../Card/helper"

import { CHAR_ADDRESS, BATTLE_ADDRESS, getCDNImage } from "helpers/constants";
import CHAR_ABI from "ABI/Characters.json";
import BATTLE_ABI from "ABI/Battle.json";
import Web3 from 'web3';
import { getCooldown } from "../Card/helper"
import { getStackCount } from "../Card/helper"

export const IsAnimated = false;
export const RosterCard = (props) => {
    //const IsAnimated = false;
    const monster = props.roster
    const helper = new CardHelper({
        isEnemyCard: false, 
        isAnimated: IsAnimated, 
        level: monster.rate, 
        name : monster.name
    });

    const dispatch = useDispatch()
    const inventory = useSelector(state => state.userDetails.inventory);
    const address = useSelector(state => state.userDetails.address);
    const roster = useSelector(state => state.userDetails.roster);
    const provider = useSelector(state => state.userDetails.provider);
    const images = useSelector(state => state.userDetails.images);

    //const web3 = new Web3(Web3.givenProvider || "http://localhost/8545");
    const web3 = new Web3(provider == null ? window.ethereum == null ? Web3.givenProvider : window.ethereum : provider);
    let battle_contract = new web3.eth.Contract(BATTLE_ABI, BATTLE_ADDRESS);
    let char_contract = new web3.eth.Contract(CHAR_ABI, CHAR_ADDRESS);
    let cooldown = roster.length == 0 ? { hours: "00", minutes: "00"} : getCooldown(roster[monster.charIndex]?.lastAttack)
    const addCharacter = () => {
        char_contract.methods.isApprovedForAll(address, BATTLE_ADDRESS).call((err, res) => {
            if(err) {
                alert("Transaction failed");
            }
            else {
                if(res) {
                    add();
                }
                else {
                    char_contract.methods.setApprovalForAll(BATTLE_ADDRESS, true).send({from: address}, (errApp, resApp) => {
                        if(errApp) {
                            alert("Transaction failed");
                        }
                        else {
                            add();
                        }
                    })
                }
            }
        })
        
    }
    const add = () => {
        battle_contract.methods.addCharacter(monster.id).send({from: address})
            .on("receipt", () => {
                let newInventory = [];
                for(var i = 0; i < inventory.length; i++) {
                    if(inventory[i].id != monster.id) {
                       newInventory.push(inventory[i]);
                    }
                }
                dispatch(setInventory(newInventory));
                let newRoster = [...roster];
                newRoster.push({
                    charId: monster.id,
                    lastAttack: 0,
                    charDetails: {
                        id: monster.id,
                        name: monster.name,
                        level: monster.rate,
                        rarity: monster.rarity == 'common' ? 0 : monster.rarity == 'uncommon' ? 1 : 2,
                        accuracy: monster.acurracy,
                        power: monster.power
                    }
                })
                dispatch(setRoster(newRoster));
            })
            .on("error", () => {
                alert("Transaction failed");
            })
    }
    const removeCharacter = () => {
        battle_contract.methods.removeCharacter(monster.charIndex).send({from: address})
            .on("receipt", () => {
                let newInventory = [...inventory];
                newInventory.push({
                    id: monster.id,
                    name: monster.name,
                    level: monster.rate,
                    rarity: monster.rarity == 'common' ? 0 : monster.rarity == 'uncommon' ? 1 : 2,
                    accuracy: monster.acurracy,
                    power: monster.power
                })
                dispatch(setInventory(newInventory));
                let newRoster = [];
                for(var i = 0; i < roster.length; i++) {
                    if(roster[i].charIndex != monster.charIndex) {
                        newRoster.push(roster[i]);
                    }
                }
                dispatch(setRoster(newRoster));
            })
            .on("error", () => {
                alert("Transaction failed");
            })
    }
    return (
        <Body className="roster-card">
            <div>
                <div className="toparea">
                    <div>
                        <img src={getCDNImage(images, monster.rarity + "_emblem.webp")}
                        className="sm-img" style={{ marginRight: '5px' }} />
                    </div>
                    <StarBadge
                        rate={monster.rate}
                        limit="4"
                        activeColor="#fff"
                        blankColor="#042e7e"
                    />
                    <div className="text-right">
                        <img src={getCDNImage(images, "monster-id.webp")} className="sm-img"/>
                    </div>
                </div>
                <div className="name-label">
                    <h2>{monster.name}</h2>
                    <h2>#{monster.id}</h2>
                </div>
            </div>
            <div className="img-area">
                <CardAvatar isAnimated={IsAnimated} file={helper.avatarSelector()} 
                width={170} avatarClass={helper.avatarClassSelector()} level={monster.rate}/>
            </div>
            <div className="footer-label">
                <StateLabelGroup title="Power" count={monster.power} titleColor="#750f29" />
                <StateLabelGroup title="Acurracy" count={monster.acurracy} titleColor="#00c4ee" />
            </div>
            {(props.button !== "Remove" || getStackCount(roster[monster.charIndex]?.lastAttack) > 0) &&
                <HistoryButton
                    background={getCDNImage(images, props.button === "Deploy" ? "green-button.png" : "red-button.png") }
                    className="btn-remove"
                    onClick={() => { props.button === "Deploy" ? addCharacter() : removeCharacter() }}
                >
                    {props.button}
                </HistoryButton>
            }
            
            {props.button === "Remove" && (
                <div className="time-label">
                    <StateLabelGroup title={cooldown.hours} count={cooldown.minutes} />
                </div>
            )}
        </Body>
    )
}

const Body = styled.div`
    border: 2px solid #fff;
    border-radius: 8px;
    padding: 5px;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    .sm-img {
        max-height: 24px;
    }

    i {
        margin-right: 2px;
    }

    .toparea {
        display: flex;
        align-items: center;
    }

    .name-label {
        color: #fff;
        display: flex;
        justify-content: space-between;
        margin-top: 3px;
    }

    .name-label h2 {
        font-size: 14px;
    }

    .img-area {
        padding: 0px 10px;
        height: 130px;
    }
    .img-area > * {
        left: ${IsAnimated ? "-20px" : "-5px"};
        top: ${IsAnimated ? "25px" : "45px"} !important;
    }
    .img-area img {
        max-width: 100%
    }

    .footer-label {
        display: flex;
        justify-content: space-between;
        padding: 10px 0px;
        width: 100%;
    }

    .footer-label .title {
        font-size: 14px;
    }

    .footer-label .state {
        font-size: 18px;
    }

    .btn-remove {
        position: absolute;
        left: 50%;
        bottom: 0px;
        transform: translate(-50%, 50%);
        border: none;
        font-size: 20px;
        padding: 0px 0px 3px 0px !important;
        width: 100px;
        height: 36px;
    }

    .time-label {
        position: absolute;
        left: 50%;
        bottom: -46px;
        transform: translate(-50%, 0px);
        background-color: rgb(43, 34, 120);
        box-shadow: inset 0px 10px 21px 0px rgba(0, 0, 0, 0.54);
        padding: 0px 5px 0px;
        border-radius: 12px;
    }
    .time-label div{
        line-height: 24px !important;
    }
    .time-label label {
        font-size: 18px;
    }
    
    width: 160px;
    height: 256px;
        
    @media (max-width: 1399px) {
        width: 140px;
        height: 224px;
        .btn-remove {
            width: 86px;
            height: 30px;
            font-size: 16px !important;
        }
        .img-area {
            height: 110px;
        }
        .img-area > * {
            left: ${IsAnimated ? "-30px" : "-15px"};
        }
        .footer-label .title {
            font-size: 11px;
        }
    
        .footer-label .state {
            font-size: 15px;
        }
    }
    @media (max-width: 1199px) {
        width: 140px;
        height: 230px;

        .sm-img {
            max-height: 20px;
        }
        i {
            font-size: 14px;
        }
        .footer-label {
            padding: 5px 0px;
        }
        .footer-label .title {
            font-size: 12px;
        }
        .footer-label .state {
            font-size: 16px;
        }
        .btn-remove {
            width: 75px;
            height: 26px;
            font-size: 14px !important;
        }
        .time-label {
            border-radius: 10px;
            bottom: -42px;
        }
        .time-label label {
            font-size: 16px;
        }
        .img-area > * {
            left: ${IsAnimated ? "-25px" : "-30px"};
            transform: scale(${IsAnimated ? "1" : "0.75"})
        }
        .img-area {
            height: 120px;
        }
    }
    @media (max-width: 991px) {
        width: 200px;
        height: auto;
        padding-bottom: 40px;
        padding: 5px 12px 45px;

        .sm-img {
            max-height: 24px;
        }
        i {
            font-size: 18px;
            margin-left: 3px;
        }
        .img-area {
            padding: 16px 10px;
            height: 130px;
        }
        .name-label h2, .footer-label .state
        {
             font-size: 24px;
        }
        .footer-label .title {
            font-size: 18px;
        }
        .footer-label .title {
            font-size: 16px;
        }
    
        .footer-label .state {
            font-size: 22px;
        }
        .btn-remove {
            width: 132px;
            height: 36px;
            font-size: 20px !important;
        }
        .time-label {
            bottom: 20px;
            padding: 0px 10px;
        }
        .img-area > * {
            left: ${IsAnimated ? "0px" : "10px"};
            top: ${IsAnimated ? "40px" : "60px"} !important;
            transform: scale(1);
        }
    }
    
    @media (max-width: 767px) {
        width: 150px;
        padding: 5px 5px 45px;
        .sm-img {
            max-height: 22px;
        }
        i {
            font-size: 14px;
            margin-left: 2px;
            margin-right: 0px;
        }
        .name-label h2, .footer-label .state
        {
            font-size: 18px;
        }
        .footer-label .title {
            font-size: 14px;
        }
        .footer-label .title {
            font-size: 12px;
        }
    
        .footer-label .state {
            font-size: 16px;
        }
        .btn-remove {
            width: 96px;
            height: 30px;
            font-size: 18px !important;
        } 
        .img-area > * {
            left: ${IsAnimated ? "-20px" : "-10px"};
            top: ${IsAnimated ? "40px" : "60px"} !important;
        }
    }

    @media (max-width: 575px) {
        width: 38vw;
        padding-top: 10px;
        .sm-img {
            max-height: 28px;
        }
        i {
            font-size: 20px;
            margin-left: 5px;
        }
        .name-label h2, .footer-label .state
        {
            font-size: 24px;
        }
        .footer-label .title {
            font-size: 18px;
        }
        .btn-remove {
            width: 60%;
            height: 6vw;
            font-size: 18px !important;
        } 
        .img-area > * {
            left: ${IsAnimated ? "0px" : "20px"};
            top: ${IsAnimated ? "40px" : "70px"} ;
        }
    }

    @media (max-width: 479px) {
        min-width: 180px;
        padding-top: 10px;
        .sm-img {
            max-height: 24px;
        }
        i {
            font-size: 16px;
            margin-left: 3px;
        }
        .name-label h2, .footer-label .state
        {
            font-size: 20px;
        }
        .footer-label .title {
            font-size: 14px;
        }
        .img-area > * {
            left: ${IsAnimated ? "-10px" : "0px"};
            top: ${IsAnimated ? "40px" : "70px"} !important;
        }
    }
`