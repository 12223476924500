import { configureStore } from "@reduxjs/toolkit"
import rosterClickReducer from "./reducer/rosterClickSlice"
import rosterCounterReducer from "./reducer/rostersCounterSlice"
import userDetailsReducer from "./reducer/userDetailsSlice"
import fightOutcomeReducer from "./reducer/fightOutcomeSlice"
export default configureStore({
    reducer: {
        rosterClick: rosterClickReducer,
        rosterCounter: rosterCounterReducer,
        userDetails: userDetailsReducer,
        fightOutcome: fightOutcomeReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})