import styled from 'styled-components';
import { Header } from '../../UI/organisms/Header'
import 'index.css'
import { useSelector } from 'react-redux';
import { LoadingSlate } from 'components/UI/organisms/LoadingSlate';
import Navbar from 'components/UI/organisms/Navbar';
export const Layout = (props) => {
    const { children } = props;
    const showLoading = useSelector(state => state.userDetails.isLoadingScreenVisible);
    
    return (    
        <Body id="body">
            <Navbar/>
            {
                showLoading && <LoadingSlate/>
            }
            <br/>
            <Header />
            {children}
        </Body>
    )
}

const Body = styled.div`
    font-family: AgencyFB-Bold;
    background: url("/img/home-bg.1da653b9.png") no-repeat 50% 7rem;
    background-size: cover;
    padding: 10px 20px;
    padding: 0;
    max-width: 100%;
    min-height: 100vh;
    @media (max-width: 991px) {
        padding: 15px;
        padding: 0;
        background: url("/img/mobile-bg.a3c60a42.png") no-repeat 50%;
        background-size: cover;
    }
`