import { createSlice } from "@reduxjs/toolkit"

export const fightOutcomSlice = createSlice({
    name: 'fightOutcome',
    initialState: {
        status: 0,
        rewards: 0
    },
    reducers: {
        setWin: state => {
            state.status = 1;
        },
        setLose: state => {
            state.status = -1;
        },
        setReset: state => {
            state.status = 0;
        },
        setNewRewards: (state, r) => {
            state.rewards = r.payload;
        }
    }
})

export const { setWin, setLose, setReset, setNewRewards } = fightOutcomSlice.actions

export default fightOutcomSlice.reducer