import styled from 'styled-components'
import { BaseButton } from './Button'
import { useDispatch } from 'react-redux'
import { toggleLoadingScreen } from 'redux/reducer/userDetailsSlice'
import { useSelector } from 'react-redux'


import BATTLE_ABI from "ABI/Battle.json";
import CHAR_ABI from "ABI/Characters.json";
import Web3 from 'web3';
import { BATTLE_ADDRESS, CHAR_ADDRESS  } from "helpers/constants";
import { setRewards, addReward, setRoster, setCurrentEnemies } from 'redux/reducer/userDetailsSlice'
import { setWin, setLose, setReset, setNewRewards } from 'redux/reducer/fightOutcomeSlice'
import { getStackCount } from 'components/UI/molecules/Card/helper'

export const FightButton = (props) => {
    const { children } = props
    const address = useSelector(state => state.userDetails.address);
    const currentCharacter = useSelector(state => state.userDetails.currentCharacter);
    const currentReward = useSelector(state => state.userDetails.reward);
    const roster = useSelector(state => state.userDetails.roster);
    const provider = useSelector(state => state.userDetails.provider);
    const dispatch = useDispatch();


    //const web3 = new Web3(Web3.givenProvider || "http://localhost/8545");
    const web3 = new Web3(provider == null ? window.ethereum == null ? Web3.givenProvider : window.ethereum : provider);
    let battle_contract = new web3.eth.Contract(BATTLE_ABI, BATTLE_ADDRESS);
    let char_contract = new web3.eth.Contract(CHAR_ABI, CHAR_ADDRESS);
    
    const combat = () => {
        battle_contract.methods.combat(currentCharacter, props.enemy - 1).send({from: address})
        .on("receipt", async () => {
            //console.log("success");
            getRoster(address);
            getEnemies();
        })
        .on("error", async () => {
            console.log("error");
            dispatch(toggleLoadingScreen(0));
        })
        .then((result) => {
            try {
                if(result != null) {
                    let reward = result.events.Combat.returnValues.reward;
                    dispatch(setNewRewards(parseInt(reward)));
                    dispatch(addReward(parseInt(reward)));
                    dispatch(reward == 0 ? setLose() : setWin());
                }
            }
            catch(e) {
                //console.log(e);
            }
        })
        .catch(console.log);
    }
    const getEnemies = async () => {
        let enemyArray = []
        for(var i = 0; i < 4; i++) {
            var e = await battle_contract.methods.enemies(i).call();
            if(e) {
                enemyArray.push(e);
            }
        }
        dispatch(setCurrentEnemies(enemyArray));
    }
    const getCharacterDetails = async(charId) => {
        let details = await char_contract.methods.charInfo(charId).call();
        details.id = charId;
        return details;
    }
    const getRoster = async (add) => {
        if(add != '') {
            let rosterDetails = await battle_contract.methods.getRoster(add).call();
            let arr = [];
            if(rosterDetails != null) {
                for(var i = 0; i < rosterDetails.length; i++) {
                    arr.push({
                        charIndex: i,
                        charId: rosterDetails[i].charId,
                        lastAttack: rosterDetails[i].lastAttack,
                        charDetails: await getCharacterDetails(rosterDetails[i].charId)
                    });
                }
                dispatch(setRoster(arr));
            }
        }
        else {
            dispatch(setRoster([]));
        }
    }
    return (
        <>
            {
                roster.length == 0 ? null :
                
                (
                    <RedButton className="btn-fight" onClick={() => {
                        if(address != '' && roster.length != 0) {
                            if(getStackCount(roster[currentCharacter].lastAttack) == 0) {
                                alert("Character is still on cooldown");
                                return;
                            }
                            dispatch(toggleLoadingScreen(props.enemy));
                            combat();
                        }
                    }}>{children}</RedButton>
                )
            }
            
        </>
    )
}

const RedButton = styled(BaseButton)`
    background: url('./img/Buttons/fight-button.png');
    background-size: 100% 100%;
    font-family: none !important;
    border: none;
    padding: 12px 32px 22px;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    text-shadow: 0px 3px 0px rgba(0, 0, 0, 0.35);
    font-size: 24px;

    @media (max-width: 1199px) {
        padding: 9px 30px 19px;
    }

    @media (max-width: 991px) {
        width: 60%;
        height: 6vw;
        font-size: 2.5vw;
        margin-top: -10px;
        padding: 0;
        padding-bottom: 0.85vw;
    }

    @media (max-width: 767px) {
        height: 7vw;
        padding-bottom: 0.9vw;
    }

    @media (max-width: 575px) {
        margin-top: -7px;
        height: 7vw;
    }

    @media (max-width: 479px) {
        margin-top: -5px;
        width: 70%;
        height: 8vw;
        padding-bottom: 1vw;
    }

    @media (max-width: 300px) {
        margin-top: 0px;
    }
`;